* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: black;
  color: white;
}

body {
  font-family: sans-serif;
}

header {
  color: white;
}

.nav-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.App-logo {
  height: 3vw;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
  display: flex;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a, .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: white;
  border-radius: 8px;
  color: black;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: white;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

/* content */

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
  margin-right: 10px;
}

.image-type-1 {
  width: 30%;
}

.image-type-2 {
  width: 65%;
}

.image-type-3 {
  width: '10px';
  height: '10px';
}

.stacked-images {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.large-image {
  display: flex;
}

.image-by-text {
  display: flex;
  flex-direction: row;
  padding-bottom: 3%;
}

.right-align-text {
  padding-left: 5%;
}

.left-align-text {
  padding-right: 5%;
}

.dan-story {
  font-size: large;
  padding: 10px;
  border-radius: 8px;
}

.mary-story {
  font-size: large;
}

.donation-request {
  font-size: large;
}

.donate-button {
  padding: 10px;
  height: 50px;
  width: 300px;
  background-color: skyblue;
  color: white;
  border-color: white;
}

ul {
  padding-left: 30px;
}

ol {
  padding-left: 30px;
}

.dan-video {
  width: 1000px;
  height: 500px;
}

.image1 {
  width: 40%;
  height: 40%;
}

@media (max-width: 768px) {
  .dan-video {
    width: 90%;
    height: 250px;
  }
}

@media (max-width: 1024px) {
  .dan-video {
    width: 90%;
    height: 500px;
  }
}
